import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[`knowledge-graph`, `cloud-native`, `fpv`, `scratch-build`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Cloud-Native, Microservices, Knowledge/Property Graphs, SPARQL,
            Word/Graph Embeddings, Big Data, Edge AI, k8s/knative, k6/locust,
            shift-left *, DevSecOps
          </h2>
          {/* <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Large imagery is at the heart of this theme</figcaption>
          </figure> */}
          <h3 id="dynamic-styles">About Me</h3>
          <p>
            Linked Data Engineer with expertise in hybrid/multi-cloud
            deployments
          </p>
          <p>
            FPV flying enthusiast, Fixed-wing, 3D flying, Collective Pitch helis
          </p>
          <p>
            In my spare time, I enjoy scratch building fixed-wing aircrafts with
            Balsa wood, but it's mostly EPP these days.
          </p>
          <p>
            In my previous role, I worked on scholarly publishing technologies
            using linked data and knowledge representation.
          </p>
        </div>
        <p>
          Challenges and finding new ways of doing things have always fascinated
          me.
        </p>
        {/* <h5 id="dynamic-styles">Skills</h5> */}
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
